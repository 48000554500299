import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Jeremy Smith', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Jeremy',
  subtitle: 'Welcome to my portfolio site',
  cta: 'About me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'I spent my youth on a commodore 64, teens on a PC and was one of four people that took the electronics class in my school year group. I tried out coding at Farnborough College in 2001, then started my career exploring roles in property management and financial advice.',
  paragraphTwo:
    'A friend reintroduced me to code in 2018, I instantly fell in love with it, so attended a coding bootcamp in Feb 2019 to try and fast track my career change. It has been an all consuming rollercoaster ride since and I am now in the lucky position that I love what I do for a living and the creative, friendly, supportive people that I work with.',
  paragraphThree:
    'I have learnt that planning, focus and keeping healthy are just as important as a a high work ethic when coding. The brain is like a muscle and your training never ends - you need to work it hard, but also feed it well and give it time to recover. I work in a great industry at an awesome time for tech and my aim is to continue learning as much as I can by finding, breaking down and solving problems.', resume:'https://github.com/JRRS1982/CV/blob/master/public/jeremy_smith_cv.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'API Request',
    info:
      'A command line http request, with dependency injection of the requester. Will take a user id and a default github requester object, and send a request to the users profile, then print_out function will let you know what the users most commonly used language (or other property) is.',
    info2: '',
    url: 'https://github.com/JRRS1982/API-Request',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Exercism',
    info:
      'A series of Kata i have completed from exercism.io in Javascript, Typescript, PHP, Ruby etc',
    info2: '',
    url: 'https://github.com/JRRS1982/Exercism',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Tutorial Projects',
    info:
      'While I put some projects that i can share publicly together, find here a collection of tutorials that I have run through, written notes and completed projects for.',
    info2: 'Courses in Typescript, React, Redux, Javascript, Docker and Laravel',
    url: 'https://github.com/JRRS1982/TutorialProjects',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to work with me? Awesome!',
  btn: "Let's Talk",
  email: 'jeremyrrsmith@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/Jeremystwita',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/jeremy-smith-2b3a9b15/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/JRRS1982',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
